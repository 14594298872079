import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
import { ref, computed, onMounted, watchEffect, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Footer from "@/components/Footer";
import { getCookie } from "@/utils";
export default {
  __name: 'App',
  setup(__props) {
    const {
      $api,
      $Toast
    } = getCurrentInstance().proxy;
    const store = useStore();
    // 获取当前路由
    const route = useRoute();
    const lang = computed(() => {
      return store.state.lang;
    });
    const invite_code = ref("");
    const globalLoading = computed(() => store.state.globalLoading);
    const inviteInfoStore = computed(() => store.state.userInviteInfo);
    onMounted(() => {
      store.commit("updateGlobalLoading", false);
      if (window.Telegram?.WebApp) {
        const {
          WebApp
        } = window.Telegram;
        WebApp?.expand();
        WebApp?.viewportStableHeight;
        WebApp?.disableVerticalSwipes();
        WebApp?.setHeaderColor("#000");
        WebApp?.setBackgroundColor("#000");
        if (WebApp?.initDataUnsafe) {
          // 存储邀请码
          invite_code.value = WebApp.initDataUnsafe?.start_param;
          watchEffect(() => {
            const access_secret = getCookie("access_secret");
            const access_token = getCookie("access_token");
            if (access_token && access_secret && !inviteInfoStore.value?.parent_invite_code && inviteInfoStore.value?.invite_code && invite_code.value && invite_code.value != inviteInfoStore.value?.invite_code) {
              console.log(invite_code.value, "可以调接口了");
              $api.setInviter({
                invite_code: invite_code.value
              }).then(res => {
                if (!res.error_code) {
                  $Toast({
                    message: "Inviter set successfully"
                  });
                }
              }).catch(() => {});
            } else {
              console.log(access_token, "access_token");
              console.log(access_secret, "access_secret");
              console.log(invite_code.value, "不能填自己的");
              console.log(inviteInfoStore.value?.invite_code, "自己的邀请码 - 对照组");
            }
          });
        }
      }
    });

    // 根据路由元信息决定是否显示 Footer
    const showFooter = computed(() => {
      return route.meta.requiresFooter !== false;
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_Loading = _resolveComponent("Loading");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['app', 'app-' + lang.value])
      }, [_createVNode(_component_router_view, {
        class: _normalizeClass([{
          'total-footer-mr': showFooter.value
        }])
      }, null, 8, ["class"]), showFooter.value ? (_openBlock(), _createBlock(_unref(Footer), {
        key: 0
      })) : _createCommentVNode("", true), globalLoading.value ? (_openBlock(), _createBlock(_component_Loading, {
        key: 1
      })) : _createCommentVNode("", true)], 2);
    };
  }
};