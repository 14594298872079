//统一管理api
import { base_tg } from "./base";
import { post, _post } from "./tool"; // 导入http中创建的axios实例

export const miniAppLogin = (params) => post(base_tg + "/tg/miniapp/login", params);
export const getUserInfo = (params) => _post(base_tg + "/tg/miniapp/user/user_info", params);
// 邀请
export const setInviter = (params) => _post(base_tg + "/tg/miniapp/invite/set", params);
export const getInviteInfo = (params) => _post(base_tg + "/tg/miniapp/invite/info", params);
export const getInviteConfig = (params) => _post(base_tg + "/tg/miniapp/invite/config", params);
export const getUserFriends = (params) => _post(base_tg + "/tg/miniapp/user/friends", params);
// 点击获取积分
export const getPointsInfo = (params) => _post(base_tg + "/tg/miniapp/activity/points_info", params);
export const setPointsClick = (params) => _post(base_tg + "/tg/miniapp/activity/points_click", params);
// 活动列表
export const getTaskList = (params) => _post(base_tg + "/tg/miniapp/activity/task", params);
export const setTaskAdd = (params) => _post(base_tg + "/tg/miniapp/activity_operation/add", params);
// 游戏
export const payGameTicket = (params) => _post(base_tg + "/tg/miniapp/activity/game_ticket", params);
export const payGamerReward = (params) => _post(base_tg + "/tg/miniapp/activity/game_reward", params);
// farming
export const setFarmingClick = (params) => _post(base_tg + "/tg/miniapp/activity/farming_click", params);
export const getFarmingReward = (params) => _post(base_tg + "/tg/miniapp/activity/farming_reward", params);

export default {
    miniAppLogin,
    getUserInfo,
    setInviter,
    getInviteInfo,
    getInviteConfig,
    getUserFriends,
    getPointsInfo,
    setPointsClick,
    getTaskList,
    setTaskAdd,
    payGameTicket,
    payGamerReward,
    setFarmingClick,
    getFarmingReward,
};
