import { ElButton } from "element-plus";
import { ElSteps } from "element-plus";
import { ElStep } from "element-plus";
export default {
  components: {
    ElButton,
    ElSteps,
    ElStep
  },
  methods: {
    // 可以写入全局方法等
  }
};