const GuidePage = () => import("@/views/GuidePage");
const Login = () => import("@/views/Login");
const Home = () => import("@/views/Home");
const Ranking = () => import("@/views/Ranking");
const Task = () => import("@/views/Task");
const Refer = () => import("@/views/Refer");
const ReferRules = () => import("@/views/ReferRules");
const Suprise = () => import("@/views/Suprise");
const VerifyGameCom = () => import("@/views/VerifyGameCom");
const Game = () => import("@/views/Game");
const routes = [
    {
        path: "/",
        redirect: {
            name: "login",
        },
    },
    {
        path: "/guide",
        name: "guide",
        component: GuidePage,
        meta: {
            requiresFooter: false,
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter: (to, from, next) => {
            // 检查是否是用户第一次进入 guide 页面
            const hasVisitedGuide = window.localStorage.getItem("hasVisitedGuide");
            if (!hasVisitedGuide) {
                next({ name: "guide" });
            } else {
                next();
            }
        },
        meta: {
            requiresFooter: false,
        },
    },
    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
            requiresFooter: true,
        },
    },
    {
        path: "/ranking",
        name: "ranking",
        component: Ranking,
        meta: {
            requiresFooter: true,
        },
    },
    {
        path: "/task",
        name: "task",
        component: Task,
        meta: {
            requiresFooter: true,
        },
    },
    {
        path: "/refer",
        name: "refer",
        component: Refer,
        beforeEnter: (to, from, next) => {
            // 检查是否是用户第一次进入 refer 页面
            const hasVisitedRefer = window.localStorage.getItem("hasVisitedRefer");
            console.log(hasVisitedRefer, "hasVisitedRefer");
            if (!hasVisitedRefer) {
                next({ name: "refer-rules" });
            } else {
                next();
            }
        },
        meta: {
            requiresFooter: true,
        },
    },
    {
        path: "/refer-rules",
        name: "refer-rules",
        component: ReferRules,
        meta: {
            requiresFooter: true,
            routeActive: "refer",
        },
    },

    {
        path: "/suprise",
        name: "suprise",
        component: Suprise,
        meta: {
            requiresFooter: true,
        },
    },
    {
        path: "/verify-game-com",
        name: "verify-game-com",
        component: VerifyGameCom,
        meta: {
            requiresFooter: false,
        },
    },
    {
        path: "/game",
        name: "game",
        component: Game,
        meta: {
            requiresFooter: false,
        },
    },
];
export default routes;
